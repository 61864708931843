<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-4">
      <!-- The link back to the index -->
      <v-btn
        text
        color="primary"
        @click="$router.replace({ name: 'AudienceOverlapHistory' })"
      >
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>

      <!-- The download button -->
      <v-btn
        depressed
        color="primary"
        :disabled="isMakingDownloadRequest"
        :loading="isMakingDownloadRequest"
        @click="triggerDownload"
      >
        <v-icon left>
          file_download
        </v-icon>

        Download PDF
      </v-btn>
    </div>

    <!-- If there's some data -->
    <div
      v-if="response"
      class="mt-6"
    >
      <v-card
        flat
        class="py-6 px-4"
      >
        <!-- Show the title and menu -->
        <div class="d-flex justify-space-between">
          <div
            class="pr-3 text-h4 font-weight-bold"
            :class="{ 'grey--text': !form.title }"
            @click="shouldShowEditDialog = true"
          >
            {{ form.title || "Untitled Report" }}
          </div>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="isMakingRequest"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the retry button -->
              <v-list-item
                @click="shouldShowRetryDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Retry
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the edit button -->
              <v-list-item
                @click="shouldShowEditDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                @click="shouldShowDeleteDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the copy URL button -->
              <v-list-item
                @click="copyReportLink"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Copy URL
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="d-flex justify-space-between align-end flex-wrap mt-6 mb-4 text-subtitle-2">
          <!-- Show the date -->
          <div>
            {{ formatDate(response.created_at) }}
          </div>

          <!-- Show the total values here -->
          <div class="d-flex flex-column flex-lg-row mt-3 mt-lg-0">
            <div>Total Followers: <b>{{ nFormatter(response.totals.total_followers) }}</b> &nbsp;</div>
            <div>Unique Followers: <b>{{ nFormatter(response.totals.total_unique_followers) }}</b> &nbsp;</div>
            <div>Overlapping Followers: <b>{{ nFormatter(response.totals.total_followers - response.totals.total_unique_followers) }}</b></div>
          </div>
        </div>

        <v-divider class="my-4" />

        <template v-if="response.errors">
          <div class="d-flex align-center rounded red white--text py-3 px-4">
            <v-icon color="white">error</v-icon>
            <div class="ml-3">There were some errors in this request</div>

            <v-spacer />

            <v-btn
              depressed
              color="white"
              class="ml-3 red--text"
              @click="shouldShowErrorsDialog = true"
            >
              View Errors
            </v-btn>
          </div>

          <v-divider class="my-4" />
        </template>

        <!-- Show the pie chart -->
        <v-row class="mb-4">
          <v-col
            cols="12"
            lg="4"
            class="d-flex justify-center align-center"
          >
            <div>
              <div class="badge">
                <div class="badge__subtitle">
                  {{ nFormatter(response.totals.total_followers - response.totals.total_unique_followers) }}
                </div>

                <div class="badge__title">
                  {{ fPercent(100 * (response.totals.total_followers - response.totals.total_unique_followers) / response.totals.total_followers) }}
                </div>

                <div class="badge__text">
                  Total Overlapping Followers
                </div>
              </div>

              <div class="badge mt-3">
                <div class="badge__subtitle">
                  {{ nFormatter(response.totals.total_unique_followers) }}
                </div>

                <div class="badge__title">
                  {{ fPercent(100 * response.totals.total_unique_followers / response.totals.total_followers) }}
                </div>

                <div class="badge__text">
                  Total Unique Followers
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" lg="4">
            <overlap-chart :model="response" />
          </v-col>

          <v-col cols="12" lg="4">
            <venn-diagram
              :influencers="response.items"
            />
          </v-col>
        </v-row>

        <v-divider class="mb-8" />

        <!-- Show the section title -->
        <div class="text-h5 font-weight-bold">
          Individual Overlaps
        </div>
        <div class="text-subtitle-2 mb-6">
          You can sort the table by clicking on the headers
        </div>

        <!-- Show the totals table -->
        <compare-table
          :model="response"
        ></compare-table>

        <template
          v-if="response.items.length > 2"
        >
          <v-divider class="my-8" />

          <!-- Show the section title -->
          <div class="d-flex flex-wrap justify-space-between">
            <div>
              <div class="text-h5 font-weight-bold">
                Overlap Comparison
              </div>
              <div class="text-subtitle-2 mb-6">
                Here's the influencer per influencer overlap
              </div>
            </div>

            <!-- Show the sorting options -->
            <div class="d-flex flex-wrap">
              <v-select
                v-model="sortInputs.sortBy"
                label="Sort By"
                :items="sortInputs.sortOptions"
                class="mr-md-3 contain-select-width"
                outlined
                dense
                @change="computeFilteredInfluencers"
              ></v-select>

              <v-select
                v-model="sortInputs.limit"
                label="Limit"
                :items="limitOptions"
                class="contain-select-width"
                outlined
                dense
                @change="computeFilteredInfluencers"
              ></v-select>
            </div>
          </div>

          <!-- Show the heat map -->
          <heat-map
            :influencers="filteredInfluencers"
          />
        </template>
      </v-card>
    </div>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete this report. This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the retry confirmation dialog -->
    <v-dialog
      v-model="shouldShowRetryDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> refresh </v-icon>

          Rerun this query?
        </v-card-title>

        <v-card-text class="pt-4">
          If succeeded, this will cost you another 1 credit!
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowRetryDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleRetrySubmit"
          >
            Retry
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="response">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this report
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copyReportLink"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model="form.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.form.title.$touch()"
            @blur="$v.form.title.$touch()"
            :hide-details="!$v.form.title.$anyError"
            :error-messages="$v.form.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="mb-6"
            outlined
            dense
          ></v-text-field>

          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the errors dialog -->
    <v-dialog
      v-model="shouldShowErrorsDialog"
      max-width="500"
      scrollable
    >
      <v-card v-if="response && response.errors">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Errors while computing this report
          </div>
        </v-card-title>

        <v-card-text class="py-0 px-3">
          <error-list :model="response" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            @click="shouldShowRetryDialog = true"
          >
            <v-icon left>
              refresh
            </v-icon>

            Retry
          </v-btn>

          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowErrorsDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import { maxLength } from "vuelidate/lib/validators"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')

const HeatMap = () => import(/* webpackChunkName: "heat-map" */ "@/components/audience-overlap/HeatMap.vue")
const ErrorList = () => import(/* webpackChunkName: "error-list" */"@/components/audience-overlap/ErrorList")
const VennDiagram = () => import(/* webpackChunkName: "venn-diagram" */ "@/components/audience-overlap/VennDiagram.vue")
const OverlapChart = () => import(/* webpackChunkName: "overlap-chart" */ "@/components/audience-overlap/OverlapChart.vue")
const CompareTable = () => import(/* webpackChunkName: "compare-table" */ "@/components/audience-overlap/CompareTable.vue")

// Subscription ID for messageEvents
const subscriptionId = Symbol("AudienceOverlap/Report")

// Export the SFC
export default {
  // Name of the component
  name: "AudienceOverlapReport",

  // Register children components
  components: {
    ProfileChip,

    HeatMap,
    ErrorList,
    VennDiagram,
    OverlapChart,
    CompareTable
  },

  // Define local data variables
  data: () => ({
    // The loading state
    isLoading: false,
    // The report data
    response: null,

    // Whether or not we're loading
    isMakingRequest: false,
    shouldShowErrorsDialog: false,
    shouldShowDeleteDialog: false,
    shouldShowRetryDialog: false,
    shouldShowEditDialog: false,

    // Whether the download request is being made
    triggerId: null,
    isMakingDownloadRequest: false,
    downloadLoaderId: null,

    // The form data
    form: {
      title: "",
      sharingAccess: "self"
    },

    // Sorting for heatmap
    sortInputs: {
      sortBy: "default",
      sortOptions: [
        { text: "Default", value: "default" },
        { text: "Lowest Followers ", value: "least_followed" },
        { text: "Highest Followers ", value: "most_followed" },
      ],

      limit: null,
    },

    vennDiagramKey: Symbol(),
    filteredInfluencers: []
  }),

  // Define vuelidate validation rules
  validations: {
    form: {
      title: {
        maxLength: maxLength(100)
      }
    }
  },

  // Define local computable properties
  computed: {
    /**
     * Get the limit options for heatmap
     *
     * @returns {Array}
     */
    limitOptions() {
      const options = [
        { text: `Show All (${this.response.items.length})`, value: null },
        { text: "First 5", value: 5 },
        { text: "First 10", value: 10 },
        { text: "First 15", value: 15 },
      ]

      return options.filter(option => (option.value || 0) <= this.response.items.length)
    },
  },

  // Define local method functions
  methods: {
    /**
     * Trigger the download of the report
     *
     * @returns {void}
     */
    triggerDownload() {
      // If the request is already being made
      if (this.isMakingDownloadRequest) return

      // Otherwise
      this.triggerId = String(Date.now())
      this.isMakingDownloadRequest = true

      // Dispatch the action
      this.$store.dispatch("audienceOverlap/downloadItem", {
        modelId: this.response.id,
        triggerId: this.triggerId
      })
    },

    /**
     * Delete the selected items
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/audience-overlaps/${this.response.id}`,
          method: "DELETE"
        })

        // If successful, redirect to the index
        this.$router.replace({ name: "AudienceOverlapHistory" })
      } catch (error) {
        // Catch an error
        // Log the error
        logger({ type: "AudienceOverlap/Delete Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Hide the dialog
      this.shouldShowDeleteDialog = false
    },

    /**
     * Handle the retry action
     *
     * @returns {void}
     */
     async handleRetrySubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/audience-overlaps/${this.response.id}/retry`,
          method: "POST"
        })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Please wait, the report is refreshing..." })
      }
      // Catch an error
      catch (error) {
        // Log the error
        logger({ type: "AudienceOverlap/Retry Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false

        // Hide the dialog
        this.shouldShowRetryDialog = false
      }
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("audienceOverlap/updateItem", { ...this.response, ...this.form })

      // Reset the request state
      this.isMakingRequest = false
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Check if the triggerId does not match
      if (this.triggerId !== event.localData.triggerId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-audience-overlap-pdf-started":
          // Show a global loader
          this.downloadLoaderId = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaderId)

          break

        case "generate-audience-overlap-pdf-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaderId)
          this.isMakingDownloadRequest = false

          break

        case "generate-audience-overlap-pdf-failed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaderId)
          this.isMakingDownloadRequest = false

          break
      }
    },

    /**
     * Copy the report link
     *
     * @return {void}
     */
    copyReportLink() {
      // Copy the link value
      const route = this.$router.resolve({ name: "AudienceOverlapReport", params: { uuid: this.response.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },

    /**
     * Compute the filtered influencers
     *
     * @returns {void}
     */
    computeFilteredInfluencers() {
      // Clone the original values
      const clone  = [...this.response.items]

      // Sort the values for ascending
      if (this.sortInputs.sortBy === "least_followed") {
        clone.sort((a, b) => a.data.followers - b.data.followers)
      }
      // Sort the values for descending
      if (this.sortInputs.sortBy === "most_followed") {
        clone.sort((a, b) => b.data.followers - a.data.followers)
      }

      // Return the sorted values
      this.filteredInfluencers = clone.slice(0, this.sortInputs.limit || clone.length)
      this.vennDiagramKey = Symbol()
    }
  },

  /**
   * As soon as the data is ready
   *
   * @returns {void}
   */
  async created() {
    // Show the loaders
    const loaderId = Symbol("AudienceOverlapReport")
    this.$store.dispatch("loaders/add", loaderId)
    this.isLoading = true

    // Fetch the report data
    try {
      // Try making the request
      const response = await axios(`/api/audience-overlaps/${this.$route.params.uuid}`)

      // Set the report data
      this.response = response.data

      // Set the form values
      this.form.title = this.response.title
      this.form.sharingAccess = this.response.sharing_access

      // Register a subscriber for messageEvents
      messageEvents.register({
        id: subscriptionId,
        module: "audience-overlap-report",
        type: "all",
        key: "all",
        validator: (event) => event.module === "audience-overlap-report" && event.localData.modelId === this.response.id,
        callback: this.handleMessageEvent
      })

      // Also compute the filtered influencers
      this.computeFilteredInfluencers()
    } catch (error) {
      // log using the helper function
      logger({ type: "AudienceOverlap/created Fetch Report", error })

      this.$store.dispatch("toasts/add", { text: "An error occurred!" })
    } finally {
      // Hide the loaders
      this.isLoading = false
      this.$store.dispatch("loaders/remove", loaderId)
    }
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 14em

.badge
  padding 12px 24px
  color white
  text-align center
  border-radius 8px
  width 262px

  &:first-child
    background-color #f05d56

  &:last-child
    background-color #2196F3

  &__subtitle
    opacity 0.9
    font-weight bold
    font-size 0.875em

  &__title
    font-size 1.5em
    line-height 1.2
    font-weight bold
    padding 6px

  &__text
    font-weight bold
    opacity 0.8
    font-size 0.875em
</style>
